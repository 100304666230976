.Layout {
    /* background-image: url("https://img.freepik.com/free-vector/little-red-heart-background_53876-98915.jpg?w=1060&t=st=1683728398~exp=1683728998~hmac=0e4ddac79c2dae92f3fbe80f65bbef4f39faf22de20709efa6d9eff623f22091"); */
    background-image: url("../Layout/valentine_background.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  