.card {
    width: 150px;
    height: 150px;
    background-image: url("memory-card-cover-b.png");
    background-size: cover;
    font-size: 30px;
    cursor: pointer;
    transition: 0.4s;
    transform-style: preserve-3d;
    border: none;
    outline: none;
    padding: 0;
    margin: 0;
    overflow: hidden; /* Prevent text from being visible when flipped */
    position: relative; /* Add this to properly position the card-inner */
    box-shadow: 0 0 10px 0px rgba(0, 0, 0, 0.089);
    border-radius: 15px;
    color: #292929;
  }


  .Card .card_back_image {
    position: absolute; /* position the img on top of the background */
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover; /* make the img cover the whole container */
    z-index: 1; /* make sure the img is on top of the text */
  }

  
  .Card img {
    width: 150px;
    height: 150px;
    object-fit: cover;
}
  
  .card:hover {
    box-shadow: 0 0 10px 1px var(--color-light-green);
  }

  .matched_card {
    outline: 3px solid rgba(19, 143, 15, 0.623);
    border: none;
    border-radius: 15px;
    transition: 0.2s;
    opacity: 0.6;
  }

  
  .flipped {
    transform: rotateY(360deg);
    background-color: var(--color-light-green);
    color: #292929;
    background-image: url("");
    /* outline: 1px solid rgb(22, 211, 22); */
  }

  .card_inner {
    font-size: 22px;
  }
  


@media only screen and (max-width: 680px) {


  .Card {
      width: calc((100% - (2 * 20px)) / 3) ;
      height: calc(((100% - (2 * 20px)) /3) * 1.5);
      font-size: 12px;
      background-size: cover;
      margin-bottom: 25px;
      display: inline-block;
    
  }

  .card{
    font-size: 24px;
  }
  .Card button {
      width: 100px;
      height: 100px;
  }

  .Card img {
      width: 100%;
      height: 100%;
      object-fit: cover;
  }

  .flipped {
      background-image: url("");
  }

  .card_inner {
    font-size: 16px;
  }
  
}
