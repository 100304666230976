.game_container {
  display: flex;
  justify-content: start;
  align-items: center;
  position: relative;
}

.game_description {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: -100px;
  /* width: 800px; */
  /* line-height: 20px; */
  text-align: center;
  direction: rtl;
  border-radius: 15px;
  box-shadow: 0px 0px 15px -4px rgba(0, 0, 0, 0.241);
  margin-top: 20px;
  margin-bottom: -20px;
  padding: 7px 20px;
  max-width: 60%;
  background-color: rgba(255, 255, 255, 0.336);
}
.game_description h4 {
  margin-bottom: 0px;
  margin-top: 0px;
}
.game_description p {
  margin-bottom: -0px;
  margin-top: 0px;
}

.game_description .winner_title {
  /* font-size: 20px; */
  font-weight: bold;
}


.MemoryGame {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 1280px;
    margin: 0px auto 0px auto;
    padding-bottom: 50px;
}

.title_h1 {
  font-size: 55px;
  color: #d34538;
  margin-bottom: -20px;
  text-align: center;
}

.MemoryGame .game-board{
    margin-top: 50px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 35px;
    /* margin-top: 100px !important; */
}
.MemoryGame .score {
  background-color: #FBDDD3;
  padding: 20px;
  opacity: 0.8;
  border-radius: 20px;
}

.MemoryGame .game-over {
    position: absolute;
    top: 76px;
    display: flex;
    width: 1000px;
    height: 500px;
    align-items: center;
}

.MemoryGame .game-over canvas {
    z-index: 0 !important;
}

.MemoryGame .total-moves {
    font-size: 35px;
    color: #292929;
}

.MemoryGame .reset-button {
    margin-top: 10px;
    width: 50%;
    height: 60px;
    font-size: 25px;
    border-radius: 15px;
    background-color: #292929;
    color: white;
    cursor: pointer !important;
}



@media only screen and (max-width: 680px) {
    .MemoryGame {
        max-width: 350px;
        /* margin-bottom: 500px; */
    }

    .MemoryGame .game-board {
        gap: 20px;
    }

    .MemoryGame .score .digits, .MemoryGame .score .total-moves {
    font-size: 24px !important;
    }

    .Modal h4 {
    font-size: 17px !important;
    }

      .Modal h4 {
      font-size: 15px !important;
    }

    .title_h1 {
      font-size: 30px;
      margin-bottom: 10px;
      }

      .game_description {
          max-width: 90%;

              }
}




.send-button {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px 22px 20px 25px;
    box-shadow: rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
    background-color: #e8e8e8;
    border-color: #ffe2e2;
    border-style: solid;
    border-width: 9px;
    border-radius: 35px;
    font-size: 25px;
    font-weight: 900;
    color: rgb(134, 124, 124);
    font-family: monospace;
    transition: transform 400ms cubic-bezier(.68,-0.55,.27,2.5), 
    border-color 400ms ease-in-out,
    background-color 400ms ease-in-out;
    word-spacing: -2px;
  }
  
  @keyframes movingBorders {
    0% {
      border-color: #fce4e4;
    }
  
    50% {
      border-color: #ffd8d8;
    }
  
    90% {
      border-color: #fce4e4;
    }
  }
  
  .send-button:hover {
    background-color: #eee;
    transform: scale(105%);
    animation: movingBorders 3s infinite;
  }
  
  .send-button svg {
    fill: rgb(255, 110, 110);
    margin-left: 13px;
  }
  
  @keyframes beatingHeart {
    0% {
      transform: scale(1);
    }
  
    15% {
      transform: scale(1.15);
    }
  
    30% {
      transform: scale(1);
    }
  
    45% {
      transform: scale(1.15);
    }
  
    60% {
      transform: scale(1);
    }
  }
  
  .send-button:hover svg {
    transform: scale(105%);
    border-color: #ffd8d8;
    animation: beatingHeart 1.2s infinite;
  }












  .game-over {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
  }
  
  .Modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .Modal__overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
  }
  
  .Modal__content {
    background-color: white !important;
    border-radius: 5px;
    padding: 20px;
    animation: slide-up 0.3s ease-in-out;
    z-index: 1;
  }
  
  @keyframes slide-up {
    from {
      transform: translateY(100%);
    }
    to {
      transform: translateY(0);
    }
  }












  .Modal {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  
  .Modal__overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1;
  }
  
  .Modal__content {
    position: relative;
    z-index: 2;
    background: white;
    padding: 2em;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    text-align: center;
    direction: rtl;
    display: flex;
    flex-direction: column;
  }
  
  .Modal__content h2 {

    color: #454275; 
    font-size: 30px;
  }
  
  .Modal__content h4 {
    margin-top: -15px;
    color: #45bead; 
    font-size: 20px;
  }
  
  .Modal__content h5 {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: -5px;
    color: #d63030; 
    font-size: 20px;
  }
  
  .Modal__content form {
    display: flex;
    flex-direction: column;
    
  }
  
  .labal_input {
    display: flex;
    margin: 10px;
  }
  .Modal__content label {
    margin-bottom: 1em;
    color: #454275; /* Deep Pink */
    display: flex;
    align-items: flex-end;
    justify-content: center;
    font-weight: 500;
    gap: 10px;
    font-size: 20px;
    width: 200px;

  }
  
  .Modal__content input {
    font-size: 20px;
    padding: 1em;
    outline: none;
    border: none;
    border-bottom: 2px solid #454275; /* Hot Pink */
    /* border-radius: 5px; */
    width: 100%;
  }
  
  .Modal__content button {
    padding: 0.8em;
    border: none;
    border-radius: 5px;
    background: linear-gradient(90deg, rgba(69,66,117,0.9) 0%, rgba(69,190,173,0.9) 100%);
    color: white;
    font-weight: bold;
    font-size: 20px;
    cursor: pointer;
    transition: background 0.3s;
    margin-top: 25px;
  }
  
  .Modal__content button:hover {
  transition: 0.1s;
  outline: 2px solid #292929;
  }


  .timer {
    /* margin: 3rem 0; */
    width: 100%;
    display: flex;
    /* height: 12%; */
    justify-content: center;
    align-items: center;
}
 
.digits {
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: 3rem;
    /* color: #f5f5f5; */
}
 
.mili-sec {
    color: #e42a2a;
}